function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

import nav_bar from "./nav_bar";
import scheduler_resize_listener from "./scheduler_resize_listener";
import eventable from "../utils/eventable";
export default function extend(scheduler) {
  eventable(scheduler);
  nav_bar(scheduler);

  scheduler._detachDomEvent = function (el, event, handler) {
    if (el.removeEventListener) {
      el.removeEventListener(event, handler, false);
    } else if (el.detachEvent) {
      el.detachEvent("on" + event, handler);
    }
  };

  scheduler._init_once = function () {
    scheduler_resize_listener(scheduler);

    scheduler._init_once = function () {};
  };

  var layout = {
    "navbar": {
      render: function render(config) {
        return scheduler._init_nav_bar(config);
      }
    },
    "header": {
      render: function render(config) {
        var element = document.createElement("div");
        element.className = "dhx_cal_header";
        return element;
      }
    },
    "dataArea": {
      render: function render(config) {
        var element = document.createElement("div");
        element.className = "dhx_cal_data";
        return element;
      }
    },
    "html_element": {
      render: function render(config) {
        return config.html;
      }
    }
  };

  function hasSchedulerMarkup(element) {
    return !!(element.querySelector(".dhx_cal_header") && element.querySelector(".dhx_cal_data") && element.querySelector(".dhx_cal_navline"));
  }

  function createDefaultHeader(scheduler) {
    var views = ["day", "week", "month"];
    var date = ["date"];
    var nav = ["prev", "today", "next"];

    if (scheduler.matrix) {
      for (var i in scheduler.matrix) {
        views.push(i);
      }
    }

    if (scheduler._props) {
      for (var i in scheduler._props) {
        views.push(i);
      }
    }

    if (scheduler._grid && scheduler._grid.names) {
      for (var i in scheduler._grid.names) {
        views.push(i);
      }
    }

    var optionalViews = ["map", "agenda", "week_agenda", "year"];
    optionalViews.forEach(function (viewName) {
      if (scheduler[viewName + "_view"]) {
        views.push(viewName);
      }
    });
    return views.concat(date).concat(nav);
  }

  scheduler.init = function (id, date, mode) {
    if (this.$destroyed) {
      return; // not have errors when try to reinit destroyed scheduler
    }

    date = date || scheduler._currentDate();
    mode = mode || "week";

    if (this._obj) {
      this.unset_actions();
    }

    this._obj = typeof id == "string" ? document.getElementById(id) : id;
    this.$container = this._obj;
    this.$root = this._obj;

    if (!this.$container.offsetHeight && this.$container.offsetWidth && this.$container.style.height === "100%") {
      // scheduler container has zero height and non-zero width
      window.console.error(scheduler._commonErrorMessages.collapsedContainer(), this.$container);
    }

    if (this.config.wai_aria_attributes && this.config.wai_aria_application_role) {
      this.$container.setAttribute("role", "application");
    }

    if (!this.config.header && !hasSchedulerMarkup(this.$container)) {
      // if no header config and no required markup - use the default header
      // so the scheduler could be initialized in an empty div
      this.config.header = createDefaultHeader(this);
      window.console.log([// jshint ignore:line
      "Required DOM elements are missing from the scheduler container and **scheduler.config.header** is not specified.", "Using a default header configuration: ", "scheduler.config.header = " + JSON.stringify(this.config.header, null, 2), "Check this article for the details: https://docs.dhtmlx.com/scheduler/initialization.html"].join("\n")); // jshint ignore:line
    }

    if (this.config.header) {
      this.$container.innerHTML = "";
      this.$container.classList.add("dhx_cal_container");

      if (this.config.header.height) {
        this.xy.nav_height = this.config.header.height;
      }

      this.$container.appendChild(layout.navbar.render(this.config.header));
      this.$container.appendChild(layout.header.render());
      this.$container.appendChild(layout.dataArea.render());
    } else {
      // if no header config provided - make sure scheduler container has all necessary elements
      if (!hasSchedulerMarkup(this.$container)) {
        throw new Error(["Required DOM elements are missing from the scheduler container.", "Be sure to either specify them manually in the markup: https://docs.dhtmlx.com/scheduler/initialization.html#initializingschedulerviamarkup", "Or to use **scheduler.config.header** setting so they could be created automatically: https://docs.dhtmlx.com/scheduler/initialization.html#initializingschedulerviaheaderconfig"].join("\n"));
      }
    }

    if (this.config.rtl) this.$container.className += " dhx_cal_container_rtl"; //hook for terrace skin

    if (this._skin_init) scheduler._skin_init();
    scheduler.date.init();
    this._scroll = true;
    this._quirks = this.$env.isIE && document.compatMode == "BackCompat";
    this._quirks7 = this.$env.isIE && navigator.appVersion.indexOf("MSIE 8") == -1;
    this._els = [];
    this.get_elements();
    this.init_templates();
    this.set_actions();

    this._init_once();

    this._init_touch_events();

    this.set_sizes();
    scheduler.callEvent('onSchedulerReady', []);
    this.setCurrentView(date, mode);
  };

  scheduler.xy = {
    min_event_height: 40,
    scale_width: 50,
    scroll_width: 18,
    scale_height: 20,
    month_scale_height: 20,
    menu_width: 25,
    margin_top: 0,
    margin_left: 0,
    editor_width: 140,
    month_head_height: 22,
    event_header_height: 14
  };
  scheduler.keys = {
    edit_save: 13,
    edit_cancel: 27
  };

  scheduler.bind = function bind(functor, object) {
    if (functor.bind) return functor.bind(object);else return function () {
      return functor.apply(object, arguments);
    };
  };

  scheduler.set_sizes = function () {
    var w = this._x = this._obj.clientWidth - this.xy.margin_left;
    var h = this._y = this._obj.clientHeight - this.xy.margin_top; //not-table mode always has scroll - need to be fixed in future

    var scale_x = this._table_view ? 0 : this.xy.scale_width + this.xy.scroll_width;
    var scale_s = this._table_view ? -1 : this.xy.scale_width;
    var materialScalePlaceholder = this.$container.querySelector(".dhx_cal_scale_placeholder");

    if (scheduler._is_material_skin()) {
      if (!materialScalePlaceholder) {
        materialScalePlaceholder = document.createElement("div");
        materialScalePlaceholder.className = "dhx_cal_scale_placeholder";
        this.$container.insertBefore(materialScalePlaceholder, this._els["dhx_cal_header"][0]);
      }

      materialScalePlaceholder.style.display = "block";
      this.set_xy(materialScalePlaceholder, w, this.xy.scale_height + 1, 0, this.xy.nav_height + (this._quirks ? -1 : 1));
    } else {
      if (materialScalePlaceholder) {
        materialScalePlaceholder.parentNode.removeChild(materialScalePlaceholder);
      }
    }

    if (this._lightbox) {
      if (scheduler.$container.offsetWidth < 1200) {} else {
        this._setLbPosition(document.querySelector(".dhx_cal_light"));
      }
    }

    this.set_xy(this._els["dhx_cal_navline"][0], w, this.xy.nav_height, 0, 0);
    this.set_xy(this._els["dhx_cal_header"][0], w - scale_x, this.xy.scale_height, scale_s, this.xy.nav_height + (this._quirks ? -1 : 1)); //to support alter-skin, we need a way to alter height directly from css

    var actual_height = this._els["dhx_cal_navline"][0].offsetHeight;
    if (actual_height > 0) this.xy.nav_height = actual_height;
    var data_y = this.xy.scale_height + this.xy.nav_height + (this._quirks ? -2 : 0);
    this.set_xy(this._els["dhx_cal_data"][0], w, h - (data_y + 2), 0, data_y + 2);
  };

  scheduler.set_xy = function (node, w, h, x, y) {
    var direction = 'left';
    node.style.width = Math.max(0, w) + "px";
    node.style.height = Math.max(0, h) + "px";

    if (arguments.length > 3) {
      if (this.config.rtl) direction = 'right';
      node.style[direction] = x + "px";
      node.style.top = y + "px";
    }
  };

  scheduler.get_elements = function () {
    //get all child elements as named hash
    var els = this._obj.getElementsByTagName("DIV");

    for (var i = 0; i < els.length; i++) {
      var class_name = scheduler._getClassName(els[i]);

      var attr_value = els[i].getAttribute("data-tab") || els[i].getAttribute("name") || "";
      if (class_name) class_name = class_name.split(" ")[0];
      if (!this._els[class_name]) this._els[class_name] = [];

      this._els[class_name].push(els[i]); //check if name need to be changed
      // scheduler.locale.labels[attr_value+"_tab"] - to fix getting labels for data-tab


      var label = scheduler.locale.labels[attr_value + "_tab"] || scheduler.locale.labels[attr_value || class_name];
      if (typeof label !== "string" && attr_value && !els[i].innerHTML) label = attr_value.split("_")[0];

      if (label) {
        this._waiAria.labelAttr(els[i], label);

        els[i].innerHTML = label;
      }
    }
  };

  var domEventsScope = scheduler._createDomEventScope();

  scheduler.unset_actions = function () {
    domEventsScope.detachAll();
  };

  scheduler.set_actions = function () {
    for (var a in this._els) {
      if (this._click[a]) {
        for (var i = 0; i < this._els[a].length; i++) {
          var element = this._els[a][i];

          var handler = this._click[a].bind(element);

          domEventsScope.attach(element, "click", handler);
        }
      }
    }

    domEventsScope.attach(this._obj, "selectstart", function (e) {
      e.preventDefault();
      return false;
    }); //this._obj.onselectstart=function(e){ return false; };

    domEventsScope.attach(this._obj, "mousemove", function (e) {
      if (!scheduler._temp_touch_block) scheduler._on_mouse_move(e);
    });
    domEventsScope.attach(this._obj, "mousedown", function (e) {
      if (!scheduler._ignore_next_click) scheduler._on_mouse_down(e);
    });
    domEventsScope.attach(this._obj, "mouseup", function (e) {
      if (!scheduler._ignore_next_click) scheduler._on_mouse_up(e);
    });
    domEventsScope.attach(this._obj, "dblclick", function (e) {
      scheduler._on_dbl_click(e);
    });
    domEventsScope.attach(this._obj, "contextmenu", function (e) {
      var ev = e;
      var src = ev.target || ev.srcElement;
      var returnValue = scheduler.callEvent("onContextMenu", [scheduler._locate_event(src), ev]);
      return returnValue;
    });
  };

  scheduler.select = function (id) {
    if (this._select_id == id) return;

    scheduler._close_not_saved();

    this.editStop(false);

    if (this._select_id) {
      this.unselect();
    }

    this._select_id = id;
    this.updateEvent(id);
    this.callEvent("onEventSelected", [id]);
  };

  scheduler.unselect = function (id) {
    if (id && id != this._select_id) return;
    var t = this._select_id;
    this._select_id = null;
    if (t && this.getEvent(t)) this.updateEvent(t);
    this.callEvent("onEventUnselected", [t]);
  };

  scheduler.getState = function () {
    return {
      mode: this._mode,
      date: new Date(this._date),
      min_date: new Date(this._min_date),
      max_date: new Date(this._max_date),
      editor_id: this._edit_id,
      lightbox_id: this._lightbox_id,
      new_event: this._new_event,
      select_id: this._select_id,
      expanded: this.expanded,
      drag_id: this._drag_id,
      drag_mode: this._drag_mode
    };
  };

  scheduler._click = {
    dhx_cal_data: function dhx_cal_data(e) {
      //in case of touch disable click processing
      if (scheduler._ignore_next_click) {
        if (e.preventDefault) e.preventDefault();
        e.cancelBubble = true;
        scheduler._ignore_next_click = false;
        return false;
      }

      var trg = e.target;

      var id = scheduler._locate_event(trg);

      if (!id) {
        scheduler.callEvent("onEmptyClick", [scheduler.getActionData(e).date, e]);
      } else {
        if (!scheduler.callEvent("onClick", [id, e]) || scheduler.config.readonly) return;
      }

      if (id && scheduler.config.select) {
        scheduler.select(id);

        var mask = scheduler._getClassName(trg);

        if (mask.indexOf("_icon") != -1) scheduler._click.buttons[mask.split(" ")[1].replace("icon_", "")](id);
      } else {
        scheduler._close_not_saved();

        if (scheduler.getState().select_id && new Date().valueOf() - (scheduler._new_event || 0) > 500) {
          scheduler.unselect();
        }
      }
    },
    dhx_cal_prev_button: function dhx_cal_prev_button() {
      scheduler._click.dhx_cal_next_button(0, -1);
    },
    dhx_cal_next_button: function dhx_cal_next_button(dummy, step) {
      var def_step = 1;

      if (scheduler.config.rtl) {
        step = -step;
        def_step = -def_step;
      }

      scheduler.setCurrentView(scheduler.date.add( //next line changes scheduler._date , but seems it has not side-effects
      scheduler.date[scheduler._mode + "_start"](new Date(scheduler._date)), step || def_step, scheduler._mode));
    },
    dhx_cal_today_button: function dhx_cal_today_button() {
      if (scheduler.callEvent("onBeforeTodayDisplayed", [])) {
        scheduler.setCurrentView(scheduler._currentDate());
      }
    },
    dhx_cal_tab: function dhx_cal_tab() {
      var name = this.getAttribute("data-tab");
      var deprecated_name = this.getAttribute("name");
      var mode = name || deprecated_name.substring(0, deprecated_name.search("_tab"));
      scheduler.setCurrentView(scheduler._date, mode);
    },
    buttons: {
      "delete": function _delete(id) {
        var c = scheduler.locale.labels.confirm_deleting;

        scheduler._dhtmlx_confirm(c, scheduler.locale.labels.title_confirm_deleting, function () {
          scheduler.deleteEvent(id);
        });
      },
      edit: function edit(id) {
        scheduler.edit(id);
      },
      save: function save(id) {
        scheduler.editStop(true);
      },
      details: function details(id) {
        scheduler.showLightbox(id);
      },
      cancel: function cancel(id) {
        scheduler.editStop(false);
      }
    }
  };

  scheduler._dhtmlx_confirm = function (message, title, callback) {
    if (!message) return callback();
    var opts = {
      text: message
    };
    if (title) opts.title = title;

    if (callback) {
      opts.callback = function (result) {
        if (result) callback();
      };
    }

    scheduler.confirm(opts);
  };

  scheduler.addEventNow = function (start, end, e) {
    var base = {};

    if (scheduler._isObject(start) && !scheduler._isDate(start)) {
      base = start;
      start = null;
    }

    var d = (this.config.event_duration || this.config.time_step) * 60000;
    if (!start) start = base.start_date || Math.round(scheduler._currentDate().valueOf() / d) * d;
    var start_date = new Date(start);

    if (!end) {
      var start_hour = this.config.first_hour;

      if (start_hour > start_date.getHours()) {
        start_date.setHours(start_hour);
        start = start_date.valueOf();
      }

      end = start.valueOf() + d;
    }

    var end_date = new Date(end); // scheduler.addEventNow(new Date(), new Date()) + collision though get_visible events defect (such event was not retrieved)

    if (start_date.valueOf() == end_date.valueOf()) end_date.setTime(end_date.valueOf() + d);
    base.start_date = base.start_date || start_date;
    base.end_date = base.end_date || end_date;
    base.text = base.text || this.locale.labels.new_event;
    base.id = this._drag_id = base.id || this.uid();
    this._drag_mode = "new-size";
    this._loading = true;
    var eventId = this.addEvent(base);
    this.callEvent("onEventCreated", [this._drag_id, e]);
    this._loading = false;
    this._drag_event = {}; //dummy , to trigger correct event updating logic

    this._on_mouse_up(e);

    return eventId;
  };

  scheduler._on_dbl_click = function (e, src) {
    src = src || e.target || e.srcElement;
    if (this.config.readonly) return;

    var name = scheduler._getClassName(src).split(" ")[0];

    switch (name) {
      case "dhx_scale_holder":
      case "dhx_scale_holder_now":
      case "dhx_month_body":
      case "dhx_wa_day_data":
        if (!scheduler.config.dblclick_create) break;
        this.addEventNow(this.getActionData(e).date, null, e);
        break;

      case "dhx_cal_event":
      case "dhx_wa_ev_body":
      case "dhx_agenda_line":
      case "dhx_grid_event":
      case "dhx_cal_event_line":
      case "dhx_cal_event_clear":
        var id = this._locate_event(src);

        if (!this.callEvent("onDblClick", [id, e])) return;
        if (this.config.details_on_dblclick || this._table_view || !this.getEvent(id)._timed || !this.config.select) this.showLightbox(id);else this.edit(id);
        break;

      case "dhx_time_block":
      case "dhx_cal_container":
        return;

      default:
        var t = this["dblclick_" + name];

        if (t) {
          t.call(this, e);
        } else {
          if (src.parentNode && src != this) return scheduler._on_dbl_click(e, src.parentNode);
        }

        break;
    }
  }; //column index by mouse x-coordinate


  scheduler._get_column_index = function (x_pos) {
    var column = 0;

    if (this._cols) {
      var width = 0;
      var i = 0;

      while (width + this._cols[i] < x_pos && i < this._cols.length) {
        width += this._cols[i];
        i++;
      }

      column = i + (this._cols[i] ? (x_pos - width) / this._cols[i] : 0);

      if (this._ignores) {
        if (column >= this._cols.length) {
          while (column >= 1 && this._ignores[Math.floor(column)]) {
            column--;
          }
        }
      }
    }

    return column;
  }; //transform mouse coordinates to day-time indexes of week based view


  scheduler._week_indexes_from_pos = function (pos) {
    //"get position" can be invoked before columns are loaded into the units view(e.g. by onMouseMove handler in key_nav.js)
    if (!this._cols) {
      return pos;
    } else {
      var column = this._get_column_index(pos.x);

      pos.x = Math.min(this._cols.length - 1, Math.max(0, Math.ceil(column) - 1));
      pos.y = Math.max(0, Math.ceil(pos.y * 60 / (this.config.time_step * this.config.hour_size_px)) - 1) + this.config.first_hour * (60 / this.config.time_step);
      return pos;
    }
  };

  scheduler._mouse_coords = function (ev) {
    var pos;
    var b = document.body;
    var d = document.documentElement;
    if (!this.$env.isIE && (ev.pageX || ev.pageY)) pos = {
      x: ev.pageX,
      y: ev.pageY
    };else pos = {
      x: ev.clientX + (b.scrollLeft || d.scrollLeft || 0) - b.clientLeft,
      y: ev.clientY + (b.scrollTop || d.scrollTop || 0) - b.clientTop
    }; //apply layout

    if (this.config.rtl && this._colsS) {
      pos.x = this.$container.querySelector(".dhx_cal_data").offsetWidth - pos.x;

      if (this._mode !== "month") {
        pos.x -= this.xy.scale_width;
      }
    } else {
      pos.x -= this.$domHelpers.getAbsoluteLeft(this._obj) + (this._table_view ? 0 : this.xy.scale_width);
    }

    var dataArea = this.$container.querySelector(".dhx_cal_data"); //pos.y-=this.$domHelpers.getAbsoluteTop(this._obj)+this.xy.nav_height+(this._dy_shift||0)+this.xy.scale_height-this._els["dhx_cal_data"][0].scrollTop;

    pos.y -= this.$domHelpers.getAbsoluteTop(dataArea) - this._els["dhx_cal_data"][0].scrollTop;
    pos.ev = ev;
    var handler = this["mouse_" + this._mode];

    if (handler) {
      pos = handler.call(this, pos);
    } else {
      //transform to date
      if (!this._table_view) {
        pos = this._week_indexes_from_pos(pos);
      } else {
        var column = this._get_column_index(pos.x);

        if (!this._cols || !this._colsS) // agenda/map views
          return pos;
        var dy = 0;

        for (dy = 1; dy < this._colsS.heights.length; dy++) {
          if (this._colsS.heights[dy] > pos.y) break;
        }

        pos.y = Math.ceil((Math.max(0, column) + Math.max(0, dy - 1) * 7) * 24 * 60 / this.config.time_step);
        if (scheduler._drag_mode || this._mode == "month") pos.y = (Math.max(0, Math.ceil(column) - 1) + Math.max(0, dy - 1) * 7) * 24 * 60 / this.config.time_step; //we care about ignored days only during event moving in month view

        if (this._drag_mode == "move") {
          if (scheduler._ignores_detected && scheduler.config.preserve_length) {
            pos._ignores = true; //get real lengtn of event

            if (!this._drag_event._event_length) this._drag_event._event_length = this._get_real_event_length(this._drag_event.start_date, this._drag_event.end_date, {
              x_step: 1,
              x_unit: "day"
            });
          }
        }

        pos.x = 0;
      }
    }

    pos.timestamp = +new Date();
    return pos;
  };

  scheduler._close_not_saved = function () {
    if (new Date().valueOf() - (scheduler._new_event || 0) > 500 && scheduler._edit_id) {
      var c = scheduler.locale.labels.confirm_closing;

      scheduler._dhtmlx_confirm(c, scheduler.locale.labels.title_confirm_closing, function () {
        scheduler.editStop(scheduler.config.positive_closing);
      });

      if (c) {
        this._drag_id = this._drag_pos = this._drag_mode = null;
      }
    }
  };

  scheduler._correct_shift = function (start, back) {
    return start -= (new Date(scheduler._min_date).getTimezoneOffset() - new Date(start).getTimezoneOffset()) * 60000 * (back ? -1 : 1);
  };

  scheduler._is_pos_changed = function (old_pos, new_pos) {
    function diff(old_val, new_val, acc) {
      return !!(Math.abs(old_val - new_val) > acc);
    }

    if (!(old_pos && this._drag_pos)) {
      return true;
    }

    var delay = 100,
        d_pos = 5; // start drag only if passed some time since mouse down, or if mouse position changed sufficiently

    return !!(this._drag_pos.has_moved || !this._drag_pos.timestamp || new_pos.timestamp - this._drag_pos.timestamp > delay || diff(old_pos.ev.clientX, new_pos.ev.clientX, d_pos) || diff(old_pos.ev.clientY, new_pos.ev.clientY, d_pos));
  };

  scheduler._correct_drag_start_date = function (start) {
    var obj;
    if (scheduler.matrix) obj = scheduler.matrix[scheduler._mode];
    obj = obj || {
      x_step: 1,
      x_unit: "day"
    };
    start = new Date(start);
    var len = 1;
    if (obj._start_correction || obj._end_correction) len = (obj.last_hour || 0) * 60 - (start.getHours() * 60 + start.getMinutes()) || 1;
    return start * 1 + (scheduler._get_fictional_event_length(start, len, obj) - len);
  };

  scheduler._correct_drag_end_date = function (start, duration) {
    var obj;
    if (scheduler.matrix) obj = scheduler.matrix[scheduler._mode];
    obj = obj || {
      x_step: 1,
      x_unit: "day"
    };

    var end = start * 1 + scheduler._get_fictional_event_length(start, duration, obj);

    return new Date(end * 1 - (scheduler._get_fictional_event_length(end, -1, obj, -1) + 1));
  };

  scheduler._on_mouse_move = function (e) {
    if (this._drag_mode) {
      var pos = this._mouse_coords(e);

      if (this._is_pos_changed(this._drag_pos, pos)) {
        var start, end;
        if (this._edit_id != this._drag_id) this._close_not_saved();
        if (!this._drag_mode) return;
        var mousedownPos = null;

        if (this._drag_pos && !this._drag_pos.has_moved) {
          mousedownPos = this._drag_pos;
          mousedownPos.has_moved = true;
        }

        this._drag_pos = pos;
        this._drag_pos.has_moved = true;

        if (this._drag_mode == "create") {
          // use mouse down position as a starting point for drag-create
          if (mousedownPos) {
            pos = mousedownPos;
          }

          this._close_not_saved();

          this.unselect(this._select_id);
          this._loading = true; //will be ignored by dataprocessor

          start = this._get_date_from_pos(pos).valueOf();

          if (!this._drag_start) {
            var res = this.callEvent("onBeforeEventCreated", [e, this._drag_id]);

            if (!res) {
              this._loading = false;
              return;
            }

            this._loading = false;
            this._drag_start = start;
            return;
          }

          end = start;

          if (end == this._drag_start) {}

          var start_date = new Date(this._drag_start);
          var end_date = new Date(end);

          if ((this._mode == "day" || this._mode == "week") && start_date.getHours() == end_date.getHours() && start_date.getMinutes() == end_date.getMinutes()) {
            end_date = new Date(this._drag_start + 1000);
          }

          this._drag_id = this.uid();
          this.addEvent(start_date, end_date, this.locale.labels.new_event, this._drag_id, pos.fields);
          this.callEvent("onEventCreated", [this._drag_id, e]);
          this._loading = false;
          this._drag_mode = "new-size";
        }

        var timeStep = this.config.time_step;
        var ev = this.getEvent(this._drag_id);
        var obj;
        if (scheduler.matrix) obj = scheduler.matrix[scheduler._mode];
        obj = obj || {
          x_step: 1,
          x_unit: "day"
        };

        if (this._drag_mode == "move") {
          start = this._min_date.valueOf() + (pos.y * this.config.time_step + pos.x * 24 * 60) * 60000;

          if (!pos.custom && this._table_view) {
            start += this.date.time_part(ev.start_date) * 1000;
          }

          if (!this._table_view && this._dragEventBody && this._drag_event._move_event_shift === undefined) {
            this._drag_event._move_event_shift = start - ev.start_date;
          }

          if (this._drag_event._move_event_shift) {
            start -= this._drag_event._move_event_shift;
          }

          start = this._correct_shift(start);

          if (pos._ignores && this.config.preserve_length && this._table_view) {
            start = scheduler._correct_drag_start_date(start);
            end = scheduler._correct_drag_end_date(start, this._drag_event._event_length);
          } else end = ev.end_date.valueOf() - (ev.start_date.valueOf() - start);
        } else {
          // resize
          start = ev.start_date.valueOf();
          end = ev.end_date.valueOf();

          if (this._table_view) {
            var resize_date = this._min_date.valueOf() + pos.y * this.config.time_step * 60000 + (pos.custom ? 0 : 24 * 60 * 60000);

            if (this._mode == "month") {
              resize_date = this._correct_shift(resize_date, false);

              if (this._drag_from_start) {
                var day = 24 * 60 * 60000;
                if (resize_date <= scheduler.date.date_part(new Date(end + day - 1)).valueOf()) // to get end time as 23:59:59 and then the day start
                  start = resize_date - day;
              } else {
                end = resize_date;
              }
            } else {
              if (this.config.preserve_length) {
                if (pos.resize_from_start) {
                  start = scheduler._correct_drag_start_date(resize_date);
                } else {
                  end = scheduler._correct_drag_end_date(resize_date, 0);
                }
              } else {
                if (pos.resize_from_start) {
                  start = resize_date;
                } else {
                  end = resize_date;
                }
              }
            }
          } else {
            var end_day_start = this.date.date_part(new Date(ev.end_date.valueOf() - 1)).valueOf();
            var end_day_date = new Date(end_day_start);
            var firstHour = this.config.first_hour;
            var lastHour = this.config.last_hour;
            var maxY = (lastHour - firstHour) * (60 / timeStep);
            this.config.time_step = 1;

            var precisePos = this._mouse_coords(e);

            this.config.time_step = timeStep;
            var minDate = pos.y * timeStep * 60000;
            var maxDate = Math.min(pos.y + 1, maxY) * timeStep * 60000;
            var preciseDate = precisePos.y * 60000; // rounding end date to the closest time step

            if (Math.abs(minDate - preciseDate) > Math.abs(maxDate - preciseDate)) {
              end = end_day_start + maxDate;
            } else {
              end = end_day_start + minDate;
            }

            end = end + (new Date(end).getTimezoneOffset() - end_day_date.getTimezoneOffset()) * 60000;
            this._els["dhx_cal_data"][0].style.cursor = "s-resize";
            if (this._mode == "week" || this._mode == "day") end = this._correct_shift(end);
          }

          if (this._drag_mode == "new-size") {
            if (end <= this._drag_start) {
              var shift = pos.shift || (this._table_view && !pos.custom ? 24 * 60 * 60000 : 0);
              start = end - (pos.shift ? 0 : shift);
              end = this._drag_start + (shift || timeStep * 60000);
            } else {
              start = this._drag_start;
            }
          } else {
            if (end <= start) end = start + timeStep * 60000;
          }
        }

        var new_end = new Date(end - 1);
        var new_start = new Date(start); //deny drag out of visible scheduler scale in timeline view

        if (this._drag_mode == "move" && scheduler.config.limit_drag_out && (+new_start < +scheduler._min_date || +end > +scheduler._max_date)) {
          if (+ev.start_date < +scheduler._min_date || +ev.end_date > +scheduler._max_date) {
            // not move event if it's already outside time scale
            new_start = new Date(ev.start_date);
            end = new Date(ev.end_date);
          } else {
            var duration = end - new_start;

            if (+new_start < +scheduler._min_date) {
              new_start = new Date(scheduler._min_date);

              if (pos._ignores && this.config.preserve_length && this._table_view) {
                new_start = new Date(scheduler._correct_drag_start_date(new_start));
                if (obj._start_correction) new_start = new Date(new_start.valueOf() + obj._start_correction);
                end = new Date(new_start * 1 + this._get_fictional_event_length(new_start, this._drag_event._event_length, obj));
              } else {
                end = new Date(+new_start + duration);
              }
            } else {
              end = new Date(scheduler._max_date);

              if (pos._ignores && this.config.preserve_length && this._table_view) {
                if (obj._end_correction) end = new Date(end.valueOf() - obj._end_correction);
                end = new Date(end * 1 - this._get_fictional_event_length(end, 0, obj, true));
                new_start = new Date(end * 1 - this._get_fictional_event_length(end, this._drag_event._event_length, obj, true));

                if (this._ignores_detected) {
                  new_start = scheduler.date.add(new_start, obj.x_step, obj.x_unit);
                  end = new Date(end * 1 - this._get_fictional_event_length(end, 0, obj, true));
                  end = scheduler.date.add(end, obj.x_step, obj.x_unit);
                }
              } else {
                new_start = new Date(+end - duration);
              }
            }
          }

          var new_end = new Date(end - 1);
        } // fix event dates when resized to bottom of the column (day/week views)


        if (!this._table_view && this._dragEventBody && !scheduler.config.all_timed && (!scheduler._get_section_view() && pos.x != this._get_event_sday({
          start_date: new Date(start),
          end_date: new Date(start)
        }) || new Date(start).getHours() < this.config.first_hour)) {
          var duration = end - new_start;

          if (this._drag_mode == "move") {
            var day = this._min_date.valueOf() + pos.x * 24 * 60 * 60000;
            new_start = new Date(day);
            new_start.setHours(this.config.first_hour);
            end = new Date(new_start.valueOf() + duration);
            new_end = new Date(end - 1);
          }
        } // fix event dates when resized to bottom of the column (day/week views)


        if (!this._table_view && !scheduler.config.all_timed && (!scheduler.getView() && pos.x != this._get_event_sday({
          start_date: new Date(end),
          end_date: new Date(end)
        }) || new Date(end).getHours() >= this.config.last_hour)) {
          var duration = end - new_start;
          var day = this._min_date.valueOf() + pos.x * 24 * 60 * 60000;
          end = scheduler.date.date_part(new Date(day));
          end.setHours(this.config.last_hour);
          new_end = new Date(end - 1);

          if (this._drag_mode == "move") {
            new_start = new Date(+end - duration);
          }
        } //prevent out-of-borders situation for day|week view


        if (this._table_view || new_end.getDate() == new_start.getDate() && new_end.getHours() < this.config.last_hour || scheduler._allow_dnd) {
          ev.start_date = new_start;
          ev.end_date = new Date(end);

          if (this.config.update_render) {
            //fix for repaint after dnd and scroll issue, #231
            var sx = scheduler._els["dhx_cal_data"][0].scrollTop;
            this.update_view();
            scheduler._els["dhx_cal_data"][0].scrollTop = sx;
          } else this.updateEvent(this._drag_id);
        }

        if (this._table_view) {
          this.for_rendered(this._drag_id, function (r) {
            r.className += " dhx_in_move dhx_cal_event_drag";
          });
        }

        this.callEvent("onEventDrag", [this._drag_id, this._drag_mode, e]);
      }
    } else {
      if (scheduler.checkEvent("onMouseMove")) {
        var id = this._locate_event(e.target || e.srcElement);

        this.callEvent("onMouseMove", [id, e]);
      }
    }
  };

  scheduler._on_mouse_down = function (e, src) {
    // on Mac we do not get onmouseup event when clicking right mouse button leaving us in dnd state
    // let's ignore right mouse button then
    if (e.button == 2) return;
    if (this.config.readonly || this._drag_mode) return;
    src = src || e.target || e.srcElement;

    var classname = scheduler._getClassName(src).split(" ")[0];

    if (this.config.drag_event_body && classname == "dhx_body") {
      if (src.parentNode && src.parentNode.className.indexOf("dhx_cal_select_menu") === -1) {
        classname = "dhx_event_move";
        this._dragEventBody = true;
      }
    }

    switch (classname) {
      case "dhx_cal_event_line":
      case "dhx_cal_event_clear":
        if (this._table_view) this._drag_mode = "move"; //item in table mode

        break;

      case "dhx_event_move":
      case "dhx_wa_ev_body":
        this._drag_mode = "move";
        break;

      case "dhx_event_resize":
        this._drag_mode = "resize";

        var fullClass = scheduler._getClassName(src);

        if (fullClass.indexOf("dhx_event_resize_end") < 0) {
          scheduler._drag_from_start = true;
        } else {
          scheduler._drag_from_start = false;
        }

        break;

      case "dhx_scale_holder":
      case "dhx_scale_holder_now":
      case "dhx_month_body":
      case "dhx_matrix_cell":
      case "dhx_marked_timespan":
        this._drag_mode = "create";
        break;

      case "":
        if (src.parentNode) return scheduler._on_mouse_down(e, src.parentNode);
        break;

      default:
        if (!scheduler.checkEvent("onMouseDown") || scheduler.callEvent("onMouseDown", [classname, e])) {
          if (src.parentNode && src != this && classname != "dhx_body") {
            return scheduler._on_mouse_down(e, src.parentNode);
          }
        }

        this._drag_mode = null;
        this._drag_id = null;
        break;
    }

    if (this._drag_mode) {
      var id = this._locate_event(src);

      if (!this.config["drag_" + this._drag_mode] || !this.callEvent("onBeforeDrag", [id, this._drag_mode, e])) this._drag_mode = this._drag_id = 0;else {
        this._drag_id = id;
        if (this._edit_id != this._drag_id || this._edit_id && this._drag_mode == "create") this._close_not_saved();
        if (!this._drag_mode) return;
        this._drag_event = scheduler._lame_clone(this.getEvent(this._drag_id) || {});
        this._drag_pos = this._mouse_coords(e);
      }
    }

    this._drag_start = null;
  };

  scheduler._get_private_properties = function (event) {
    var fields = {};

    for (var i in event) {
      if (i.indexOf("_") === 0) {
        fields[i] = true;
      }
    }

    return fields;
  };

  scheduler._clear_temporary_properties = function (clean, flagged_event) {
    var initial = this._get_private_properties(clean);

    var current_state = this._get_private_properties(flagged_event);

    for (var i in current_state) {
      if (!initial[i]) {
        delete flagged_event[i];
      }
    }
  };

  scheduler._on_mouse_up = function (e) {
    if (e && e.button == 2 && this._mobile) return;

    if (this._drag_mode && this._drag_id) {
      this._els["dhx_cal_data"][0].style.cursor = "default"; //drop

      var drag_id = this._drag_id;
      var mode = this._drag_mode;
      var moved = !this._drag_pos || this._drag_pos.has_moved;
      delete this._drag_event._move_event_shift;
      var ev = this.getEvent(this._drag_id);

      if (moved && (this._drag_event._dhx_changed || !this._drag_event.start_date || ev.start_date.valueOf() != this._drag_event.start_date.valueOf() || ev.end_date.valueOf() != this._drag_event.end_date.valueOf())) {
        var is_new = this._drag_mode == "new-size";

        if (!this.callEvent("onBeforeEventChanged", [ev, e, is_new, this._drag_event])) {
          if (is_new) this.deleteEvent(ev.id, true);else {
            this._drag_event._dhx_changed = false;

            this._clear_temporary_properties(ev, this._drag_event);

            scheduler._lame_copy(ev, this._drag_event);

            this.updateEvent(ev.id);
          }
        } else {
          this._drag_id = this._drag_mode = null;

          if (is_new && this.config.edit_on_create) {
            this.unselect();
            this._new_event = new Date(); //timestamp of creation
            //if selection disabled - force lightbox usage

            if (this._table_view || this.config.details_on_create || !this.config.select || !this.isOneDayEvent(this.getEvent(drag_id))) {
              scheduler.callEvent("onDragEnd", [drag_id, mode, e]);
              return this.showLightbox(drag_id);
            }

            this._drag_pos = true; //set flag to trigger full redraw

            this._select_id = this._edit_id = drag_id;
          } else {
            if (!this._new_event) this.callEvent(is_new ? "onEventAdded" : "onEventChanged", [drag_id, this.getEvent(drag_id)]);
          }
        }
      }

      if (this._drag_pos && (this._drag_pos.has_moved || this._drag_pos === true)) {
        this._drag_id = this._drag_mode = null; // set null to prevent _sorder recalculation for drag event

        this.render_view_data(); //redraw even if there is no real changes - necessary for correct positioning item after drag
      }

      scheduler.callEvent("onDragEnd", [drag_id, mode, e]);
    }

    this._drag_id = null;
    this._drag_mode = null;
    this._drag_pos = null;
  };

  scheduler._trigger_dyn_loading = function () {
    if (this._load_mode && this._load()) {
      this._render_wait = true;
      return true;
    } else {
      return false;
    }
  };

  scheduler.update_view = function () {
    this._reset_ignores();

    this._update_nav_bar(this.config.header, this.$container.querySelector(".dhx_cal_navline"));

    var view = this[this._mode + "_view"];

    if (view) {
      view.call(this, true);
    } else {
      this._reset_scale();
    }

    if (this._trigger_dyn_loading()) {
      return true;
    }

    this.render_view_data();
  };

  scheduler.isViewExists = function (mode) {
    return !!(scheduler[mode + "_view"] || scheduler.date[mode + "_start"] && scheduler.templates[mode + "_date"] && scheduler.templates[mode + "_scale_date"]);
  };

  scheduler._set_aria_buttons_attrs = function () {
    var buttonGroups = ["dhx_cal_next_button", "dhx_cal_prev_button", "dhx_cal_tab", "dhx_cal_today_button"];

    for (var i = 0; i < buttonGroups.length; i++) {
      var group = this._els[buttonGroups[i]];

      for (var j = 0; group && j < group.length; j++) {
        var name = group[j].getAttribute("data-tab") || group[j].getAttribute("name");
        var label = this.locale.labels[buttonGroups[i]];

        if (name) {
          label = this.locale.labels[name + "_tab"] || this.locale.labels[name] || label;
        }

        if (buttonGroups[i] == "dhx_cal_next_button") {
          label = this.locale.labels.next;
        } else if (buttonGroups[i] == "dhx_cal_prev_button") {
          label = this.locale.labels.prev;
        }

        this._waiAria.headerButtonsAttributes(group[j], label || "");
      }
    }
  };

  scheduler.updateView = function (date, mode) {
    if (!this.$container) {
      throw new Error("The scheduler is not initialized. \n **scheduler.updateView** or **scheduler.setCurrentView** can be called only after **scheduler.init**");
    }

    date = date || this._date;
    mode = mode || this._mode;
    var dhx_cal_data = 'dhx_cal_data';
    var container = this._obj;
    var oldClass = "dhx_scheduler_" + this._mode;
    var newClass = "dhx_scheduler_" + mode;

    if (!this._mode || container.className.indexOf(oldClass) == -1) {
      container.className += " " + newClass;
    } else {
      container.className = container.className.replace(oldClass, newClass);
    }

    var dhx_multi_day = 'dhx_multi_day';
    var prev_scroll = this._mode == mode && this.config.preserve_scroll ? this._els[dhx_cal_data][0].scrollTop : false; // saving current scroll

    var multidayScroll;

    if (this._els[dhx_multi_day] && this._els[dhx_multi_day][0]) {
      multidayScroll = this._els[dhx_multi_day][0].scrollTop;
    } //hide old custom view


    if (this[this._mode + "_view"] && mode && this._mode != mode) this[this._mode + "_view"](false);

    this._close_not_saved();

    if (this._els[dhx_multi_day]) {
      this._els[dhx_multi_day][0].parentNode.removeChild(this._els[dhx_multi_day][0]);

      this._els[dhx_multi_day] = null;
    }

    this._mode = mode;
    this._date = date;
    this._table_view = this._mode == "month";
    this._dy_shift = 0; //correction for multiday section in week/day views
    //show new view

    this.update_view();

    this._set_aria_buttons_attrs();

    var tabs = this._els["dhx_cal_tab"];

    if (tabs) {
      //calendar can work without view tabs
      for (var i = 0; i < tabs.length; i++) {
        var tab = tabs[i];

        if (tab.getAttribute("data-tab") == this._mode || tab.getAttribute("name") == this._mode + "_tab") {
          tab.classList.add("active");

          this._waiAria.headerToggleState(tab, true);
        } else {
          tab.classList.remove("active");

          this._waiAria.headerToggleState(tab, false);
        }
      }
    }

    if (typeof prev_scroll == "number") // if we are updating or working with the same view scrollTop should be saved
      this._els[dhx_cal_data][0].scrollTop = prev_scroll; // restoring original scroll

    if (typeof multidayScroll == "number" && this._els[dhx_multi_day] && this._els[dhx_multi_day][0]) {
      this._els[dhx_multi_day][0].scrollTop = multidayScroll;
    }
  };

  scheduler.setCurrentView = function (date, mode) {
    if (!this.callEvent("onBeforeViewChange", [this._mode, this._date, mode || this._mode, date || this._date])) return;
    this.updateView(date, mode);
    this.callEvent("onViewChange", [this._mode, this._date]);
  };

  scheduler.render = function (date, mode) {
    scheduler.setCurrentView(date, mode);
  };

  scheduler._render_x_header = function (i, left, d, h, offset_top) {
    offset_top = offset_top || 0; //header scale

    var head = document.createElement("div");
    head.className = "dhx_scale_bar";

    if (this.templates[this._mode + "_scalex_class"]) {
      //'_scalex_class' - timeline already have similar template, use the same name
      head.className += ' ' + this.templates[this._mode + "_scalex_class"](d);
    }

    var width = this._cols[i] - 1;

    if (this._mode == "month" && i === 0 && this.config.left_border) {
      head.className += " dhx_scale_bar_border";
      left = left + 1;
    }

    this.set_xy(head, width, this.xy.scale_height - 2, left, offset_top); //-1 for border

    var columnHeaderText = this.templates[this._mode + "_scale_date"](d, this._mode); //TODO - move in separate method

    head.innerHTML = columnHeaderText;

    this._waiAria.dayHeaderAttr(head, columnHeaderText);

    h.appendChild(head);
  };

  scheduler._get_columns_num = function (from, to) {
    var count = 7;

    if (!scheduler._table_view) {
      var count_n = scheduler.date["get_" + scheduler._mode + "_end"];
      if (count_n) to = count_n(from);
      count = Math.round((to.valueOf() - from.valueOf()) / (1000 * 60 * 60 * 24));
    }

    return count;
  };

  scheduler._get_timeunit_start = function () {
    //start date of currently displayed time unit(day, week,...)
    return this.date[this._mode + "_start"](new Date(this._date.valueOf()));
  };

  scheduler._get_view_end = function () {
    var dd = this._get_timeunit_start();

    var ed = scheduler.date.add(dd, 1, this._mode);

    if (!scheduler._table_view) {
      var count_n = scheduler.date["get_" + scheduler._mode + "_end"];
      if (count_n) ed = count_n(dd);
    }

    return ed;
  };

  scheduler._calc_scale_sizes = function (width, from, to) {
    //
    //calculates number of displayed columns(days/units/month view cols) and their widths
    var rtl = this.config.rtl;
    var summ = width; //border delta

    var count = this._get_columns_num(from, to); //if (this.config.rtl) this._process_ignores(scheduler.date.add(to, -1, "day"), count, "day", -1);
    //else


    this._process_ignores(from, count, "day", 1);

    var realcount = count - this._ignores_detected;

    for (var i = 0; i < count; i++) {
      if (this._ignores[i]) {
        this._cols[i] = 0;
        realcount++;
      } else {
        this._cols[i] = Math.floor(summ / (realcount - i));
      }

      summ -= this._cols[i];
      this._colsS[i] = (this._cols[i - 1] || 0) + (this._colsS[i - 1] || (this._table_view ? 0 : (rtl ? this.xy.scroll_width : this.xy.scale_width) + 2)); //this._colsS[j]=(this._cols[rtl ? j+1 : (i-1)]||0)+(this._colsS[rtl ? j+1 : (i-1)]||(this._table_view?0:(rtl?this.xy.scroll_width:this.xy.scale_width)+2));
    }

    this._colsS['col_length'] = count;
    this._colsS[count] = this._cols[count - 1] + this._colsS[count - 1] || 0; //this._colsS[count] = (this._cols[rtl ? 0 : count-1]+this._colsS[rtl ? 0 : count-1]) || 0;
  };

  scheduler._set_scale_col_size = function (div, width, left) {
    var c = this.config;
    this.set_xy(div, width - 1, c.hour_size_px * (c.last_hour - c.first_hour), left + this.xy.scale_width + 1, 0); //-1 for border
  };

  scheduler._render_scales = function (header, data_area) {
    //render columns in week/units view, or header in month view
    var sd = new Date(scheduler._min_date),
        ed = new Date(scheduler._max_date),
        today = this.date.date_part(scheduler._currentDate());
    var summ = parseInt(header.style.width, 10); //border delta

    var d = new Date(this._min_date); // if (this.config.rtl) {
    // 	d = new Date(scheduler.date.add(this._max_date, -1, "day"));
    // }

    var count = this._get_columns_num(sd, ed);

    this._calc_scale_sizes(summ, sd, ed);

    var left = 0;
    header.innerHTML = "";

    for (var i = 0; i < count; i++) {
      if (!this._ignores[i]) {
        this._render_x_header(i, left, d, header);
      }

      if (!this._table_view) {
        var scales = document.createElement("div");
        var cls = "dhx_scale_holder";
        if (d.valueOf() == today.valueOf()) cls = "dhx_scale_holder_now";
        scales.setAttribute("data-column-index", i);

        if (this._ignores_detected && this._ignores[i]) {
          cls += " dhx_scale_ignore";
        }

        scales.className = cls + " " + this.templates.week_date_class(d, today);

        this._waiAria.dayColumnAttr(scales, d);

        this._set_scale_col_size(scales, this._cols[i], left);

        data_area.appendChild(scales);
        this.callEvent("onScaleAdd", [scales, d]);
      }

      left += this._cols[i]; //if (this.config.rtl) d=this.date.add(d,-1,"day");
      //else

      d = this.date.add(d, 1, "day");
      d = this.date.day_start(d);
    }
  };

  scheduler._getNavDateElement = function () {
    return this.$container.querySelector(".dhx_cal_date");
  };

  scheduler._reset_scale = function () {
    //current mode doesn't support scales
    //we mustn't call reset_scale for such modes, so it just to be sure
    if (!this.templates[this._mode + "_date"]) return;
    var h = this._els["dhx_cal_header"][0];
    var data_area = this._els["dhx_cal_data"][0];
    var c = this.config;
    h.innerHTML = ""; //data_area.scrollTop = 0; //fix flickering in FF; makes IE8 flicker instead

    data_area.innerHTML = "";
    var str = (c.readonly || !c.drag_resize ? " dhx_resize_denied" : "") + (c.readonly || !c.drag_move ? " dhx_move_denied" : "");
    data_area.className = "dhx_cal_data" + str;
    this._scales = {};
    this._cols = []; //store for data section

    this._colsS = {
      height: 0
    };
    this._dy_shift = 0;
    this.set_sizes();
    var d, sd;

    var dd = this._get_timeunit_start(),
        ed = scheduler._get_view_end();

    d = sd = this._table_view ? scheduler.date.week_start(dd) : dd;
    this._min_date = d;
    var navBarDateStr = this.templates[this._mode + "_date"](dd, ed, this._mode);

    var scaleElement = this._getNavDateElement();

    if (scaleElement) {
      scaleElement.innerHTML = navBarDateStr;

      this._waiAria.navBarDateAttr(scaleElement, navBarDateStr);
    }

    this._max_date = ed;

    scheduler._render_scales(h, data_area);

    if (this._table_view) // month view
      this._reset_month_scale(data_area, dd, sd);else {
      this._reset_hours_scale(data_area, dd, sd);

      if (c.multi_day) {
        var dhx_multi_day = 'dhx_multi_day';

        if (this._els[dhx_multi_day]) {
          this._els[dhx_multi_day][0].parentNode.removeChild(this._els[dhx_multi_day][0]);

          this._els[dhx_multi_day] = null;
        }

        var navline = this._els["dhx_cal_navline"][0];
        var top = navline.offsetHeight + this._els["dhx_cal_header"][0].offsetHeight + 1;
        var c1 = document.createElement("div");
        c1.className = dhx_multi_day;
        c1.style.visibility = "hidden";
        var totalWidth = this._colsS[this._colsS.col_length];
        var offset = c.rtl ? this.xy.scale_width : this.xy.scroll_width;
        var hiddenWidth = Math.max(totalWidth + offset - 2, 0);
        this.set_xy(c1, hiddenWidth, 0, 0, top); // 2 extra borders, dhx_header has -1 bottom margin

        data_area.parentNode.insertBefore(c1, data_area);
        var c2 = c1.cloneNode(true);
        c2.className = dhx_multi_day + "_icon";
        c2.style.visibility = "hidden";
        this.set_xy(c2, this.xy.scale_width, 0, 0, top); // dhx_header has -1 bottom margin

        c1.appendChild(c2);
        this._els[dhx_multi_day] = [c1, c2];
        scheduler.event(this._els[dhx_multi_day][0], "click", this._click.dhx_cal_data);
      }
    }
  };

  scheduler._reset_hours_scale = function (b, dd, sd) {
    var c = document.createElement("div");
    c.className = "dhx_scale_holder";
    var date = new Date(1980, 1, 1, this.config.first_hour, 0, 0);

    for (var i = this.config.first_hour * 1; i < this.config.last_hour; i++) {
      var cc = document.createElement("div");
      cc.className = "dhx_scale_hour";
      cc.style.height = this.config.hour_size_px + "px";
      var width = this.xy.scale_width;

      if (this.config.left_border) {
        cc.className += " dhx_scale_hour_border";
      }

      cc.style.width = width + "px";
      var content = scheduler.templates.hour_scale(date);
      cc.innerHTML = content;

      this._waiAria.hourScaleAttr(cc, content);

      c.appendChild(cc);
      date = this.date.add(date, 1, "hour");
    }

    b.appendChild(c);
    if (this.config.scroll_hour) b.scrollTop = this.config.hour_size_px * (this.config.scroll_hour - this.config.first_hour);
  };

  scheduler._currentDate = function () {
    if (scheduler.config.now_date) {
      return new Date(scheduler.config.now_date);
    }

    return new Date();
  };

  scheduler._reset_ignores = function () {
    this._ignores = {};
    this._ignores_detected = 0;
  };

  scheduler._process_ignores = function (sd, n, mode, step, preserve) {
    this._reset_ignores();

    var ignore = scheduler["ignore_" + this._mode];

    if (ignore) {
      var ign_date = new Date(sd);

      for (var i = 0; i < n; i++) {
        if (ignore(ign_date)) {
          this._ignores_detected += 1;
          this._ignores[i] = true;
          if (preserve) n++;
        }

        ign_date = scheduler.date.add(ign_date, step, mode);
        if (scheduler.date[mode + '_start']) ign_date = scheduler.date[mode + '_start'](ign_date);
      }
    }
  };

  scheduler._render_month_scale = function (div, dd
  /*month start*/
  , sd
  /*view start*/
  , rows) {
    //renders month view layout
    var ed = scheduler.date.add(dd, 1, "month"),
        view_start = new Date(sd);

    var cd = scheduler._currentDate();

    this.date.date_part(cd);
    this.date.date_part(sd);
    rows = rows || Math.ceil(Math.round((ed.valueOf() - sd.valueOf()) / (60 * 60 * 24 * 1000)) / 7);
    var tdwidths = [];

    for (var i = 0; i <= 7; i++) {
      var cell_width = (this._cols[i] || 0) - 1;

      if (i === 0 && this.config.left_border) {
        cell_width = cell_width - 1;
      }

      tdwidths[i] = cell_width + "px";
    }

    function getCellHeight(row) {
      var h = scheduler._colsS.height;

      if (scheduler._colsS.heights[row + 1] !== undefined) {
        h = scheduler._colsS.heights[row + 1] - (scheduler._colsS.heights[row] || 0);
      }

      return h;
    }

    var cellheight = 0;
    var table = document.createElement("table");
    table.setAttribute("cellpadding", "0");
    table.setAttribute("cellspacing", "0");
    var tableBody = document.createElement("tbody");
    table.appendChild(tableBody);
    var rendered_dates = [];

    for (var i = 0; i < rows; i++) {
      var row = document.createElement("tr");
      tableBody.appendChild(row);
      var row_height = Math.max(getCellHeight(i) - scheduler.xy.month_head_height, 0);

      for (var j = 0; j < 7; j++) {
        var cell = document.createElement("td");
        row.appendChild(cell);
        var cls = "";
        if (sd < dd) cls = 'dhx_before';else if (sd >= ed) cls = 'dhx_after';else if (sd.valueOf() == cd.valueOf()) cls = 'dhx_now';

        if (this._ignores_detected && this._ignores[j]) {
          cls += " dhx_scale_ignore";
        }

        cell.className = cls + " " + this.templates.month_date_class(sd, cd);
        cell.setAttribute("data-cell-date", scheduler.templates.format_date(sd));
        var body_class = "dhx_month_body";
        var head_class = "dhx_month_head";

        if (j === 0 && this.config.left_border) {
          body_class += " dhx_month_body_border";
          head_class += " dhx_month_head_border";
        }

        if (!this._ignores_detected || !this._ignores[j]) {
          this._waiAria.monthCellAttr(cell, sd);

          var cellHead = document.createElement("div");
          cellHead.className = head_class;
          cellHead.innerHTML = this.templates.month_day(sd);
          cell.appendChild(cellHead);
          var cellBody = document.createElement("div");
          cellBody.className = body_class;
          cellBody.style.height = row_height + "px";
          cellBody.style.width = tdwidths[j];
          cell.appendChild(cellBody);
        } else {
          cell.appendChild(document.createElement("div"));
          cell.appendChild(document.createElement("div"));
        }

        rendered_dates.push(sd);
        var bf1 = sd.getDate();
        sd = this.date.add(sd, 1, "day");
        if (sd.getDate() - bf1 > 1) sd = new Date(sd.getFullYear(), sd.getMonth(), bf1 + 1, 12, 0);
      }

      scheduler._colsS.heights[i] = cellheight;
      cellheight += getCellHeight(i);
    }

    this._min_date = view_start;
    this._max_date = sd;
    div.innerHTML = "";
    div.appendChild(table);
    this._scales = {};
    var divs = div.getElementsByTagName('div');

    for (var i = 0; i < rendered_dates.length; i++) {
      // [header, body, header, body, ...]
      var div = divs[i * 2 + 1];
      var date = rendered_dates[i];
      this._scales[+date] = div;
    }

    for (var i = 0; i < rendered_dates.length; i++) {
      var date = rendered_dates[i];
      this.callEvent("onScaleAdd", [this._scales[+date], date]);
    }

    return this._max_date;
  };

  scheduler._reset_month_scale = function (b, dd, sd, rows) {
    //recalculates rows height and redraws month layout
    var ed = scheduler.date.add(dd, 1, "month"); //trim time part for comparation reasons

    var cd = scheduler._currentDate();

    this.date.date_part(cd);
    this.date.date_part(sd);
    rows = rows || Math.ceil(Math.round((ed.valueOf() - sd.valueOf()) / (60 * 60 * 24 * 1000)) / 7);
    var height = Math.floor(b.clientHeight / rows) - this.xy.month_head_height;
    this._colsS.height = height + this.xy.month_head_height;
    this._colsS.heights = [];
    return scheduler._render_month_scale(b, dd, sd, rows);
  };

  scheduler.getView = function (viewName) {
    if (!viewName) {
      viewName = scheduler.getState().mode;
    }

    if (scheduler.matrix && scheduler.matrix[viewName]) {
      return scheduler.matrix[viewName];
    }

    if (scheduler._props && scheduler._props[viewName]) {
      return scheduler._props[viewName];
    }

    return null;
  };

  scheduler.getLabel = function (property, key) {
    var sections = this.config.lightbox.sections;

    for (var i = 0; i < sections.length; i++) {
      if (sections[i].map_to == property) {
        var options = sections[i].options;

        for (var j = 0; j < options.length; j++) {
          if (options[j].key == key) {
            return options[j].label;
          }
        }
      }
    }

    return "";
  };

  scheduler.updateCollection = function (list_name, collection) {
    var list = scheduler.serverList(list_name);
    if (!list) return false;
    list.splice(0, list.length);
    list.push.apply(list, collection || []);
    scheduler.callEvent("onOptionsLoad", []);
    scheduler.resetLightbox();
    scheduler.hideCover();
    return true;
  };

  scheduler._lame_clone = function (object, cache) {
    var i, t, result; // iterator, types array, result

    cache = cache || [];

    for (i = 0; i < cache.length; i += 2) {
      if (object === cache[i]) return cache[i + 1];
    }

    if (object && _typeof(object) == "object") {
      result = Object.create(object); // preserve prototype methods

      t = [Array, Date, Number, String, Boolean];

      for (i = 0; i < t.length; i++) {
        if (object instanceof t[i]) result = i ? new t[i](object) : new t[i](); // first one is array
      }

      cache.push(object, result);

      for (i in object) {
        if (Object.prototype.hasOwnProperty.apply(object, [i])) result[i] = scheduler._lame_clone(object[i], cache);
      }
    }

    return result || object;
  };

  scheduler._lame_copy = function (target, source) {
    for (var key in source) {
      if (source.hasOwnProperty(key)) {
        target[key] = source[key];
      }
    }

    return target;
  };

  scheduler._get_date_from_pos = function (pos) {
    var start = this._min_date.valueOf() + (pos.y * this.config.time_step + (this._table_view ? 0 : pos.x) * 24 * 60) * 60000; //if (this.config.rtl) start=scheduler.date.add(this._max_date, -1, "day").valueOf()+(pos.y*this.config.time_step-(this._table_view?0:pos.x)*24*60)*60000;

    return new Date(this._correct_shift(start));
  }; // n_ev - native event


  scheduler.getActionData = function (n_ev) {
    var pos = this._mouse_coords(n_ev);

    return {
      date: this._get_date_from_pos(pos),
      section: pos.section
    };
  };

  scheduler._focus = function (node, select) {
    if (node && node.focus) {
      if (this._mobile) {
        window.setTimeout(function () {
          node.focus();
        }, 10);
      } else {
        try {
          if (select && node.select && node.offsetWidth) {
            node.select();
          }

          node.focus();
        } catch (e) {}
      }
    }
  }; //non-linear scales


  scheduler._get_real_event_length = function (sd, fd, obj) {
    var ev_length = fd - sd;
    var hours = obj._start_correction + obj._end_correction || 0;
    var ignore = this["ignore_" + this._mode];
    var start_slot = 0,
        end_slot;

    if (obj.render) {
      start_slot = this._get_date_index(obj, sd);
      end_slot = this._get_date_index(obj, fd);
    } else {
      end_slot = Math.round(ev_length / 60 / 60 / 1000 / 24);
    }

    var last_column = true;

    while (start_slot < end_slot) {
      var check = scheduler.date.add(fd, -obj.x_step, obj.x_unit);

      if (ignore && ignore(fd) && (!last_column || last_column && ignore(check))) {
        ev_length -= fd - check;
      } else {
        last_column = false;
        ev_length -= hours;
      }

      fd = check;
      end_slot--;
    }

    return ev_length;
  };

  scheduler._get_fictional_event_length = function (end_date, ev_length, obj, back) {
    var sd = new Date(end_date);
    var dir = back ? -1 : 1; //get difference caused by first|last hour

    if (obj._start_correction || obj._end_correction) {
      var today;
      if (back) today = sd.getHours() * 60 + sd.getMinutes() - (obj.first_hour || 0) * 60;else today = (obj.last_hour || 0) * 60 - (sd.getHours() * 60 + sd.getMinutes());
      var per_day = (obj.last_hour - obj.first_hour) * 60;
      var days = Math.ceil((ev_length / (60 * 1000) - today) / per_day);
      if (days < 0) days = 0;
      ev_length += days * (24 * 60 - per_day) * 60 * 1000;
    }

    var fd = new Date(end_date * 1 + ev_length * dir);
    var ignore = this["ignore_" + this._mode];
    var start_slot = 0,
        end_slot;

    if (obj.render) {
      start_slot = this._get_date_index(obj, sd);
      end_slot = this._get_date_index(obj, fd);
    } else {
      end_slot = Math.round(ev_length / 60 / 60 / 1000 / 24);
    }

    while (start_slot * dir <= end_slot * dir) {
      var check = scheduler.date.add(sd, obj.x_step * dir, obj.x_unit);

      if (ignore && ignore(sd)) {
        ev_length += (check - sd) * dir;
        end_slot += dir;
      }

      sd = check;
      start_slot += dir;
    }

    return ev_length;
  };

  scheduler._get_section_view = function () {
    return this.getView();
  };

  scheduler._get_section_property = function () {
    if (this.matrix && this.matrix[this._mode]) {
      return this.matrix[this._mode].y_property;
    } else if (this._props && this._props[this._mode]) {
      return this._props[this._mode].map_to;
    }

    return null;
  };

  scheduler._is_initialized = function () {
    var state = this.getState();
    return this._obj && state.date && state.mode;
  };

  scheduler._is_lightbox_open = function () {
    var state = this.getState();
    return state.lightbox_id !== null && state.lightbox_id !== undefined;
  };
}